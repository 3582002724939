@font-face {
font-family: '__gtAmericaExtended_0e6abd';
src: url(/_next/static/media/67e03f354ced8341-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__gtAmericaExtended_0e6abd';
src: url(/_next/static/media/5ec91041f780fcd7-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: normal;
}@font-face {font-family: '__gtAmericaExtended_Fallback_0e6abd';src: local("Arial");ascent-override: 77.74%;descent-override: 20.06%;line-gap-override: 0.00%;size-adjust: 128.63%
}.__className_0e6abd {font-family: '__gtAmericaExtended_0e6abd', '__gtAmericaExtended_Fallback_0e6abd'
}.__variable_0e6abd {--gt-america-extended: '__gtAmericaExtended_0e6abd', '__gtAmericaExtended_Fallback_0e6abd'
}

@font-face {
font-family: '__gtAmerica_472cf0';
src: url(/_next/static/media/d2f736b85ce1753d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__gtAmerica_472cf0';
src: url(/_next/static/media/3582f3c0309ef02d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__gtAmerica_472cf0';
src: url(/_next/static/media/f1c33117647e1241-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__gtAmerica_Fallback_472cf0';src: local("Arial");ascent-override: 98.50%;descent-override: 25.41%;line-gap-override: 0.00%;size-adjust: 101.52%
}.__className_472cf0 {font-family: '__gtAmerica_472cf0', '__gtAmerica_Fallback_472cf0'
}.__variable_472cf0 {--gt-america: '__gtAmerica_472cf0', '__gtAmerica_Fallback_472cf0'
}

@font-face {
font-family: '__rivalsNumbersLogo_17a121';
src: url(/_next/static/media/e73a13c4c8b02822-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: normal;
}@font-face {font-family: '__rivalsNumbersLogo_Fallback_17a121';src: local("Arial");ascent-override: 80.00%;descent-override: 20.00%;line-gap-override: 20.00%;size-adjust: 100.00%
}.__className_17a121 {font-family: '__rivalsNumbersLogo_17a121', '__rivalsNumbersLogo_Fallback_17a121';font-weight: normal;font-style: normal
}.__variable_17a121 {--rivals-numbers-logo: '__rivalsNumbersLogo_17a121', '__rivalsNumbersLogo_Fallback_17a121'
}

@font-face {
font-family: '__rivalsNumbersRanking_b1ef0c';
src: url(/_next/static/media/3b9d912993b2a1b1-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: normal;
}@font-face {font-family: '__rivalsNumbersRanking_Fallback_b1ef0c';src: local("Arial");ascent-override: 80.00%;descent-override: 20.00%;line-gap-override: 20.00%;size-adjust: 100.00%
}.__className_b1ef0c {font-family: '__rivalsNumbersRanking_b1ef0c', '__rivalsNumbersRanking_Fallback_b1ef0c';font-weight: normal;font-style: normal
}.__variable_b1ef0c {--rivals-numbers-ranking: '__rivalsNumbersRanking_b1ef0c', '__rivalsNumbersRanking_Fallback_b1ef0c'
}

@font-face {
font-family: '__yahooSans_bc3d35';
src: url(/_next/static/media/4c1554d9a3a3dd82-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: normal;
}

@font-face {
font-family: '__yahooSans_bc3d35';
src: url(/_next/static/media/aaaaa55ce914ed09-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: italic;
}

@font-face {
font-family: '__yahooSans_bc3d35';
src: url(/_next/static/media/f4813d6de58b2292-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__yahooSans_bc3d35';
src: url(/_next/static/media/1eac3a84dd8c2948-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__yahooSans_bc3d35';
src: url(/_next/static/media/87852d0e92bceb08-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: normal;
}

@font-face {
font-family: '__yahooSans_bc3d35';
src: url(/_next/static/media/07c43d4ac02f3969-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: italic;
}

@font-face {
font-family: '__yahooSans_bc3d35';
src: url(/_next/static/media/a1011f0500fc10f6-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: '__yahooSans_bc3d35';
src: url(/_next/static/media/294a47b6610e7051-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__yahooSans_bc3d35';
src: url(/_next/static/media/ea7ca980dff464b5-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__yahooSans_bc3d35';
src: url(/_next/static/media/602db2875da447f0-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__yahooSans_bc3d35';
src: url(/_next/static/media/8f02447accde6b71-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__yahooSans_bc3d35';
src: url(/_next/static/media/2ee28239f10cb780-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__yahooSans_bc3d35';
src: url(/_next/static/media/bac7a55549e645d0-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: '__yahooSans_Fallback_bc3d35';src: local("Arial");ascent-override: 94.52%;descent-override: 21.59%;line-gap-override: 0.00%;size-adjust: 102.84%
}.__className_bc3d35 {font-family: '__yahooSans_bc3d35', '__yahooSans_Fallback_bc3d35'
}.__variable_bc3d35 {--yahoo-sans: '__yahooSans_bc3d35', '__yahooSans_Fallback_bc3d35'
}

